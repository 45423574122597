.Nav {
    height: 100%;
    background-color: var(--dark-bg);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
    padding: 0 2rem;
    font-size: 1.3rem;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

    &>* {
        margin: 0 15px;
    }

    div {
        a {
            margin: 0 1.8rem;
            color: var(--font);
            transition: color 0.5s ease, transform 0.3s ease;

            &:hover {
                color: var(--white);
                transform: scale(1.1);
            }
        }
    }


    @media only screen and (max-width: 768px) {
        justify-content: center;

        .logoContainer {
            display: none;

        }

        div {
            a {
                margin: 0 1.6rem;
            }
        }
    }

    @media only screen and (max-width: 1100px) {

        .logoContainer {
            img {
                height: 40px;
            }
        }
    }

    @media only screen and (max-width: 480px) {
        font-size: medium;

        div {
            a {
                margin: 0 1.2rem;
            }
        }
    }
}