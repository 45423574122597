.ProjectsCard {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
    margin: 2rem 0;
    padding: 3rem;
    gap: 5%;
    background-color: lightgray;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    .projectCardData {
        width: 50%;

        &>* {
            margin-top: 1.5rem;
        }

        h2 {
            font-size: x-large;
        }

        p {
            font-size: large;
        }

        .headerLine {
            padding-bottom: 1.5em !important;

            &::after {
                width: 60% !important;
            }

            &:hover {
                transform: scale(1.0) !important;
            }
        }
    }


    .btn-p {
        padding: 0.4em 2em;
        margin: 2em 0;
    }


    &.visible {
        animation: fadeIn 2s ease 0s 1 normal forwards;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: scale(0.6);
        }

        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        text-align: center;
        margin: 5em 10em;

        .projectCardImage {
            display: flex;
            justify-content: center;

            img {
                width: 400px;
            }

        }

        .projectCardData {
            width: 100%;
            // width: 60%;
            overflow: hidden;

            .TechStack {
                .stackItem {
                    padding: 10px 15px;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        margin: 5em 25em;

        .projectCardImage {
            display: flex;
            justify-content: center;

            img {
                width: 400px;
            }

        }

        .projectCardData {
            // width: 60%;
            overflow: hidden;

            .TechStack {
                .stackItem {
                    padding: 10px 15px;
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        margin: 5em 22em;

        .projectCardImage {

            img {
                width: 200px;
            }
        }

        .projectCardData {
            width: 100%;

            .TechStack {
                .stackItem {
                    padding: 5px 10px;
                    width: auto;
                }
            }
        }
    }

}