.Layout {
    display: grid;
    grid-template-rows: repeat(6, 1fr);

    nav,
    main,
    footer {
        grid-row: span 6;
        height: 100%;
        overflow: hidden;

    }

    nav {
        position: fixed;
        width: 100%;
        height: 10vh;
        z-index: 50;
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 100vh;
    }

    footer {
        min-height: 10vh;
    }
}