.Contact {
    height: 100%;
    background-image: url("../../../Assets/Images/home-bg.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding: 5vh 0;

    h1 {
        font-size: 46px;
        width: fit-content;
        margin: 0.6em auto;
    }

    p {
        font-size: x-large;
        padding: 0 auto;
    }

    .contactHeaderContainer {
        &.visible_header {
            animation: fadeInUp 1s ease-in-out;
        }
    }

    .form {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        background: white;
        padding: 3rem 4rem;
        text-align: left;
        width: 70%;
        border-radius: 5px;
        box-sizing: border-box;

        .inputContainer {
            margin: 15px;

            &> :nth-child(2) {
                background-color: var(--bg);
                border: none;
            }

            label,
            input,
            textarea {
                display: block;
                width: 100%;
                margin: 10px;
                line-height: 3rem;
                font-size: large;
                margin: 0;

                &::placeholder {
                    font-family: monospace;
                }
            }
        }

        .formBtn {
            display: flex;
            justify-content: right;
            margin-top: 30px;

            .btn-p {
                padding: 0.6em 2em;
            }
        }

        &.visible_form {
            animation: formAnim 2s ease 0s 1 normal forwards;
        }
    }

    @keyframes formAnim {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @media (max-width: 768px) {
        .form {
            width: 40%;
        }
    }
}