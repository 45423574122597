.Section {

    .homeParaContainer {
        padding: 0 10rem;
        height: 100vh;
        background-image: url("../../../../Assets/Images/home-bg.jpg");
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .paraContainer {

            h1 {
                width: fit-content;
                margin: 0 auto;
                font-size: 64px;
            }

            h4 {
                font-size: 32px;
            }

            h6 {
                font-size: 24px;
            }

            .btn-p {
                padding: 0.6em 2em;
            }

            h1,
            h4,
            h6,
            .btn-p {
                opacity: 0;
                transform: translateY(20px);
                transition: opacity 5s, transform 5s;
            }

            h1.visible,
            h4.visible,
            h6.visible,
            .btn-p.visible {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .mouseContainer {
            display: flex;
            justify-content: center;

            .mouse {
                width: 25px;
                height: 40px;
                border: 2px solid #333;
                border-radius: 60px;
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    width: 5px;
                    height: 5px;
                    position: absolute;
                    top: 7px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #333;
                    border-radius: 50%;
                    opacity: 1;
                    animation: wheel 1.3s infinite;
                    -webkit-animation: wheel 1.3s infinite;
                }
            }
        }
    }

    .homeIconsContainer {
        position: absolute;
        top: 25%;
        left: -3px;

        .SocialIcons {
            display: flex;
            flex-direction: column;
            border: 3px solid;
            background-color: var(--bg);
            border-radius: 0px 15px 15px 0;
            padding: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

            a {
                color: var(--black);
                margin: 10px 0;
            }

            img {
                width: 36px;
                height: 36px;
                transition: transform 0.5s ease;

                &:hover {
                    transform: scale(1.2);
                }
            }
        }
    }


    @keyframes wheel {
        100% {
            opacity: 0;
            top: 27px;
        }
    }

    @media only screen and (max-width: 768px) {
        .homeParaContainer {
            padding: 0 27rem;

            .paraContainer {

                h1 {
                    font-size: 42px;

                }

                h4 {
                    font-size: 20px;
                }

                h6 {
                    font-size: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .homeParaContainer {
            .paraContainer {
                h1 {
                    font-size: 24px;
                }

                h4 {
                    font-size: 18px;
                }

                h6 {
                    font-size: 14px;
                }
            }
        }

        .homeIconsContainer {
            .SocialIcons {
                a {
                    margin: 5px 0;
                }

                img {
                    width: 28px;
                    height: 28px;
                    transition: transform 0.5s ease;

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}