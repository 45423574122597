.About {
    padding: 5vh 15vh;

    .aboutPara {

        h1 {
            font-size: xx-large;
            width: fit-content;
            margin: 1em auto;
        }

        p {
            font-size: x-large;
        }

        &.visible {
            animation: fadeInUp 1s ease-in-out;
        }
    }

    .aboutData {
        display: flex;
        justify-content: space-between;

        .aboutSection {
            width: 45%;

            h4 {
                font-size: xx-large;
                width: fit-content;
                margin: 1em auto;
            }

            .aboutContentDetails {
                font-size: 1.3rem;
                color: #666;
                max-width: 60rem;
                line-height: 1.7;
                margin-bottom: 1rem;
                text-align: start;

                a {
                    padding: 0 5px;
                    color: var(--black);
                    font-weight: bold;
                }
            }

            .btn-p {
                padding: 0.4em 2em;
                margin: 1em;
            }

            &.visible_left {
                animation: slideInFromLeft 1s ease-in-out;
            }

            &.visible_right {
                animation: slideInFromRight 1s ease-in-out;
            }
        }
    }

    @keyframes slideInFromRight {
        from {
            transform: translateX(120%);
        }

        to {
            transform: translateX(0);
        }
    }

    @keyframes slideInFromLeft {
        from {
            transform: translateX(-120%);
        }

        to {
            transform: translateX(0);
        }
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }


    @media only screen and (max-width: 768px) {
        padding: 5vh 27vh;

        .aboutData {

            flex-direction: column;
            align-items: center;

            .aboutSection {
                width: 80%;

                .aboutContentDetails {
                    text-align: center;
                }
            }
        }
    }

    @media only screen and (max-width: 430px) {
        padding: 5vh 35vh;

        .aboutData {
            flex-direction: column;
            align-items: center;

            .aboutSection {
                .aboutContentDetails {
                    text-align: center;
                }
            }
        }
    }
}