.Footer {
    padding: 0 2rem;
    height: 100%;
    background-color: var(--dark-bg);
    display: flex;
    justify-content: space-between;
    font-size: larger;
    box-sizing: border-box;

    div {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 20px;

        a {
            text-decoration: none;
            color: var(--black);
        }

        img {
            height: 25px;
            width: 25px;
        }
    }

    .menuContainer {
        flex-direction: column-reverse;
    }

    .cpContainer {
        height: 100%;
    }

    @media (max-width: 768px) {

        &>* {
            flex-direction: column;
            justify-content: space-around;
        }

        .menuContainer {
            &>* {
                gap: 20px;
            }
        }
    }

    @media (max-width: 480px) {
        flex-direction: column-reverse;
        padding: 10px 0;
        gap: 20px;
    }
}