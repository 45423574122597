.Logo {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;

    img {
        color: black;
        height: 10vh;
        border-radius: 25%;
        margin-right: 15px;
    }

    a {
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
}