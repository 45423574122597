body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  font-family: monospace;

  --black: black;
  --white: white;
  --lighter: #F7F7F7;
  --bg: #EEEEEE;
  --dark-bg: #929AAB;
  --btn: #393E46;

  .btn-p {
    display: inline-block;
    border: 3px solid var(--black);
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    transition: all 0.2s;
    animation: bouncy 5s infinite linear;
    position: relative;
    color: var(--white);
    background-color: var(--btn);
    font-size: x-large;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    &:hover {
      background-color: var(--dark-bg);
      color: var(--black);
    }
  }

  .headerLine {
    position: relative;
    padding-bottom: 15px;
    transition: transform 0.3s ease-in-out;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 2px;
      background-color: var(--dark-bg);
    }
    &:hover{
      transform: scale(1.1);
    }
  }

  @keyframes bouncy {
    0% {
      top: 0em;
    }

    40% {
      top: 0em;
    }

    43% {
      top: -0.9em;
    }

    46% {
      top: 0em;
    }

    48% {
      top: -0.4em;
    }

    50% {
      top: 0em;
    }

    100% {
      top: 0em;
    }
  }
}