.ProjectsList {
    .projectsContainer {
        padding: 0vh 10vh;
    }

    .projectCaption {
        margin-bottom: 6rem;

        h1 {
            font-size: xx-large;
            width: fit-content;
            margin: 1em auto;
        }

        p {
            font-size: x-large;
        }
    }

    @media only screen and (max-width: 768px) {
        .projectCaption {
            padding: 0vh 40vh;

        }
    }

    @media only screen and (max-width: 1200px) {
        .projectsContainer {
            padding: 0vh;
        }
    }
}